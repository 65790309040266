<template>
    <div style="overflow-x: hidden;margin-bottom: 75px;" class="act-form production-act-form">
        <error-list :errors="errors" :message-function="errorMessage" @clear="errors = []"></error-list>

        <b-tabs pills card v-if="act" v-model="tabIndex">
            <b-tab title="Продукция" active>
                <!--склад - дата - смена-->
                <div class="card">
                    <div class="card-header" v-b-toggle.info style="font-size: 18px;font-weight: 600;">
                        <span class="dropdown-toggle">
                            <span v-if="act.storage">
                                {{ act.storage.name }}
                            </span>
                            <span v-if="act.shift || act.startDate">
                                ({{ act.startDate ? moment(act.startDate).format('DD.MM.YY') : '' }}<template
                                v-if="act.startDate && act.shift"> / </template>{{ act.shift ? act.shift.name : '' }})
                            </span>
                        </span>
                    </div>
                    <b-collapse id="info" v-model="infoCollapsed">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <b-form-group description="Цех">
                                        <storage-picker :selectable-for-task-list="true"
                                                        v-model="act.storage"
                                                        :disabled="storagePickerDisabled"></storage-picker>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6" style="padding-right: .1rem">
                                    <b-form-group description="Дата" style="margin-bottom: 0">
                                        <input type="date" class="form-control" v-model="act.startDate"
                                               :disabled="startDateDisabled">
                                    </b-form-group>
                                </div>
                                <div class="col-6" style="padding-left: .1rem">
                                    <b-form-group description="Смена" style="margin-bottom: 0">
                                        <shift-input v-model="act.shift" :disabled="shiftDisabled"></shift-input>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>

                <!--номенклатура - время-->
                <b-card no-body>
                    <template #header>
                        <div v-b-toggle.time style="font-size: 18px;font-weight: 600;">
                            <span class="dropdown-toggle">
                                <span v-if="act.nomenclature">
                                    {{ act.nomenclature.name }}
                                </span>
                                <span :class="act.endAt ? '' : 'btn-orange'">
                                    ({{ act.startAt }} - {{ act.endAt }})
                                </span>
                            </span>
                        </div>
                    </template>
                    <b-collapse id="time" v-model="timeCollapsed">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <b-form-group description="Номенклатура">
                                        <nomenclature-input v-model="act.nomenclature"
                                                            :disabled="nomenclatureInputDisabled"></nomenclature-input>
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6" style="padding-right: .1rem">
                                    <b-form-group description="Начало" style="margin-bottom: 0; position: relative">
                                        <input type="time" class="form-control pr-4" v-model="act.startAt"
                                               :disabled="disabled">
                                        <b-icon-clock-fill v-show="!disabled" @click="act.startAt = moment().format('HH:mm')" />
                                    </b-form-group>
                                </div>
                                <div class="col-6" style="padding-left: .1rem">
                                    <b-form-group description="Конец" style="margin-bottom: 0; position: relative">
                                        <input type="time" class="form-control pr-4" v-model="act.endAt"
                                               :disabled="disabled">
                                        <b-icon-clock-fill v-show="!disabled" @click="act.endAt = moment().format('HH:mm')" />
                                    </b-form-group>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <b-form-checkbox v-model="act.assume_finished"
                                                     :disabled="!allowedToSetAssumeFinished">
                                        Считать выполненным
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </b-card>

                <temperature-after-shock-table
                    v-if="isHotStorage"
                    :production-act-items="act.items"
                />

                <!--задания-->
                <div v-if="act.tasks && act.tasks.length"
                     class="text-muted"
                     style="font-size: small;padding: 10px 10px 0!important;display: flex;justify-content: space-between">
                    <span>По заданиям</span>
                    {{ totalTaskCount }} {{ measureUnit }}
                </div>

                <!--итого-->
                <div class="text-muted"
                     style="font-size: small;padding: 10px!important;display: flex;justify-content: space-between">
                    <span>Итого</span>
                    <span>{{ totalCount }} {{ measureUnit }}</span>
                </div>

                <div class="card" v-for="(item, index) in act.items"
                     :key="item.id"
                     @click="selectItem(item)"
                     :class="'bg-' + (item.variant || 'default')"
                     style="padding: 5px;"
                >
                    <div class="row align-items-center" style="margin: 0;">
                        <div class="col-3" style="padding: 0 0 0 .2rem ;">
                            <input v-model="item.box.name" placeholder="Ящик" :disabled="disabled || itemBlocked(item)" class="form-control">
                        </div>
                        <div class="col-3" style="padding: 0 0 0 .2rem;">
                            <input type="number" required class="form-control item-input" step="0.0001" v-model="item.count" placeholder="Кол-во"
                                   :disabled="disabled || itemBlocked(item)">
                        </div>
                        <div class="col-3" style="padding: 0 0 0 1rem; text-align: center;">
                            <template v-if="item.actualShift">
                                <span>{{ item.actualShift.name }}</span>
                            </template>
                            <template v-else-if="item.actual_shift">
                                <span>{{ item.actual_shift.name }}</span>
                            </template>
                            <template v-else>
                                &nbsp;
                            </template>
                        </div>
                        <div class="col-2" style="padding: 0 0 0 1rem;">
                            <b-form-checkbox v-model="item.defect" :disabled="disabled || itemBlocked(item)">Брак</b-form-checkbox>
                        </div>
                        <div class="col-1" style="padding: 0;" v-show="selectedItem === item && !disabled && !itemBlocked(item)">
                            <div style="line-height: 35px; float: right; margin-right: 5px;">
                                <b-icon-x-circle-fill @click="removeItem(index)" variant="danger" />
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab title="Сырьё">
                <b-form-group>
                    <div
                        class="row"
                        style="margin-top:15px;margin-bottom:15px;"
                        v-if="act && act.id && act.rawMaterials.length > 0 && accessForCreateQualityControlAct"
                    >
                        <div class="col-11"></div>
                        <div class="col-1">
                            <b-form-checkbox
                                @change="selectAllRawMaterials"
                                v-model="allSelectedRawMaterials"
                                :indeterminate="indeterminateRawMaterials"
                                id="all-raw-materials-checkbox"
                                name="all-raw-materials-checkbox"
                            ></b-form-checkbox>
                        </div>
                    </div>
                    <b-form-checkbox-group
                        v-model="selectedRawMaterials"
                        id="raw-materials-checkbox-group"
                        name="raw-materials-checkbox-group"
                    >
                        <div class="card"
                             v-for="(item, index) in act.rawMaterials"
                             :key="item.id"
                             :class="'bg-' + (item.variant || 'default')"
                        >
                            <div class="row">
                                <div class="col-11">
                                    <div class="card-body">
                                        <div style="float: right;" @click="selectRawMaterial(item)">
                                            <span
                                                style="line-height: 24px;margin-right:20px;"
                                                :class="
                                                    parseFloat( item.remains ) > parseFloat( item.count ) &&
                                                    parseFloat( item.count ) > 0 ?
                                                    'valid-item' :
                                                    'not-valid-item'
                                                "
                                            >
                                                Остаток: {{ item.remains }}
                                                <template v-if="item.nomenclature">
                                                    {{ item.nomenclature.measureUnit.name }}
                                                </template>
                                            </span>
                                            <span
                                                style="line-height: 24px;"
                                                :class="isRawMaterialValid(item) ? 'valid-item' : 'not-valid-item'"
                                            >
                                                {{ item.count ? item.count : '---' }}
                                                <template v-if="item.nomenclature">
                                                    {{ item.nomenclature.measureUnit.name }}
                                                </template>
                                            </span>
                                        </div>

                                        <h6 style="margin-bottom: 0; line-height: 24px;"
                                            @click="selectRawMaterial(item)">
                                            {{ item.nomenclature ? item.nomenclature.name : '---' }}
                                        </h6>

                                        <b-collapse :id="item.id + ''" accordion="rawMaterials"
                                                    v-model="item.collapsed">
                                            <b-form-group description="Номенклатура" style="padding-top: 10px;">
                                                <nomenclature-input v-model="item.nomenclature"
                                                                    @input="changeRawMaterialNomenclature( item, index )"
                                                                    :disabled="disabled || !!item.nomenclatureLot"
                                                ></nomenclature-input>
                                            </b-form-group>
                                            <b-form-group description="Партия" v-if="item.nomenclature">
                                                <nomenclature-lot-pick v-model="item.nomenclatureLot"
                                                                       :available-items="nomenclatureLots"
                                                                       :nomenclatureId="item.nomenclature.id"
                                                                       :disabled="disabled"
                                                ></nomenclature-lot-pick>
                                            </b-form-group>
                                            <b-form-group style="margin-bottom: 0;">
                                                <div class="row" style="margin: 0;">
                                                    <div class="col-5" style="padding: 0 0 0 .2rem ;">
                                                        <input v-model="item.box.name" placeholder="Ящик"
                                                               :disabled="disabled"
                                                               class="form-control">
                                                    </div>
                                                    <div class="col-5" style="padding: 0 0 0 .2rem ;">
                                                        <b-input-group
                                                            :append="item.nomenclature ? item.nomenclature.measureUnit.name : ''">
                                                            <input type="number" class="form-control"
                                                                   v-model="item.count"
                                                                   placeholder="Кол-во" :disabled="disabled">
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-2"
                                                         style="padding: 0; display: flex;justify-content: center;align-items: center;"
                                                         v-show="!disabled">
                                                        <b-icon-x-circle-fill @click="removeRawMaterial(index)" variant="danger" />
                                                    </div>
                                                </div>
                                            </b-form-group>
                                        </b-collapse>
                                    </div>
                                </div>
                                <div class="col-1 text-center d-flex align-items-center">
                                    <b-form-checkbox
                                        :value="index"
                                        v-if="act && act.id && accessForCreateQualityControlAct"
                                    ></b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </b-form-checkbox-group>
                </b-form-group>
            </b-tab>

            <b-tab v-if="act.tasks && act.tasks.length"
                   :title="taskTabTitle"
            >
                <div class="card">
                    <table class="table table-bordered table-sm" style="margin: 0">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Продукция</th>
                            <th>Кол-во</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(task, index) in act.tasks" :key="task.id">
                            <td style="vertical-align: middle;">{{ task.id }}</td>
                            <td style="vertical-align: middle;">{{ task.plan.nomenclature.name }} {{ measureUnit }}</td>
                            <td style="vertical-align: middle;">{{ task.count }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </b-tab>

            <b-tab :title="(act.comment ? '⭐' : '') + 'Коммент.'">
                <div class="card">
                    <div class="card-body">
                        <textarea v-model="act.comment" rows="12" wrap="soft" class="form-control"
                                  :disabled="disabled"></textarea>
                    </div>
                </div>
            </b-tab>
        </b-tabs>

        <success-message v-model="successCountDown"></success-message>

        <control-panel v-show="act">
            <b-button variant="danger" v-show="!disabled" @click="$router.go(-1)">Отмена</b-button>
            <b-button @click="addItem" v-show="tabIndex === 0 && !disabled" variant="success" >
                <b-icon-plus scale="1.3" />
            </b-button>
            <b-button @click="addRawMaterial" v-show="tabIndex === 1 && !disabled" variant="success">
                <b-icon-plus scale="1.3" />
            </b-button>

            <b-dropdown
                class="m-2"
                text="Качество"
                variant="warning"
                v-if="act && act.id && accessForCreateQualityControlAct"
            >
                <b-dropdown-item @click="createQualityControlAct()">Продукция</b-dropdown-item>
                <b-dropdown-item
                    v-if="selectedRawMaterials.length"
                    @click="createQualityControlAct( true )"
                >Сырье
                </b-dropdown-item>
            </b-dropdown>

            <b-button variant="success" @click="save($event, false)" v-show="!disabled">Записать</b-button>
        </control-panel>

        <div class="card" v-if="act && (showTransferButton || tabIndex === 1)" style="margin-top: 10px">
            <div class="card-body">
                <div class="row">
                    <div class="col-6 text-center">
                        <b-form-checkbox
                            v-model="act.use_proportional_raw_materials"
                            switch
                            size="lg"
                        >
                            {{ act.use_proportional_raw_materials ? ' Норма' : ' Вес' }}
                        </b-form-checkbox>
                    </div>
                    <div class="col-6 text-right" v-if="showTransferButton">
                        <b-button v-if="storageFrom && !storageFrom.packing" :disabled="disabled">
                            <i class="fas fa-angle-double-right"></i> в {{ storageTo.name }} <i
                            class="fas fa-arrow-right"></i>
                        </b-button>
                        <hr v-if="act.materialTransferActs.length" class="m-1">
                        <b-button v-for="transferAct in act.materialTransferActs" :key="transferAct.id" variant="link" class="mb-1 ml-1"
                                  :to="{name:'MaterialTransferActUpdate', params:{id:transferAct.id}}">
                            <i class="fas fa-circle" :style="{'color': transferAct.canceled ? 'red' : (transferAct.received ? 'green' : 'orange')}"></i>
                            <i class="fas fa-angle-double-right"></i> № {{ transferAct.id }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <users-of-the-act :act="act"></users-of-the-act>
    </div>
</template>

<script>
    import { BOX_OBJECT_TYPE, NOMENCLATURE_LOT_OBJECT_TYPE } from "@utils/objectTypes";
    import {
        NOMENCLATURE_LOTS_ENDPOINT,
        NOMENCLATURES_ENDPOINT,
        PRODUCTION_ACTS_ENDPOINT,
        PRODUCTION_PLANS_ENDPOINT, WEIGHING_SCALES_ENDPOINT
    } from '@utils/endpoints';
    import { ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR } from "@utils/Roles";
    import BarcodeRepository from '../../utils/BarcodeRepository';
    import { mapActions, mapGetters } from 'vuex';
    import MultiSelect from 'vue-multiselect';
    import { sumBy, uniqBy, difference, forEach } from 'lodash';
    import ControlPanel from '../_common/ControlPanel';
    import ErrorList from '../_common/ErrorList';
    import SuccessMessage from '../_common/SuccessMessage';
    import NomenclatureInput from "../_common/NomenclatureInput";
    import NomenclatureLotPick from "../_common/NomenclatureLotPick";
    import ShiftInput from "../_common/ShiftInput";
    import StoragePicker from "../_common/StoragePicker";
    import WeighingScale from "../_common/WeighingScale/WeighingScale";
    import moment from 'moment';
    import { $app } from '@/main';
    import UsersOfTheAct from '../_common/UsersOfTheAct';
    import Barcode from "../../utils/Barcode";
    import TemperatureAfterShockTable from "../_common/TemperatureAfterShockTable";

    const ALLOWED_OVERPRODUCTION_PERCENT = 10;

    const WITH = [
        'tasks.plan.nomenclature.measureUnit',
        'materialTransferActs',
        'items.box',
        'items.actualShift',
        'storage',
        'shift',
        'tasks.parent.storage',
        'tasks.storage',
        'rawMaterials.nomenclature.measureUnit',
        'rawMaterials.nomenclatureLot',
        'nomenclature.measureUnit'
    ];

    export default {
        components: {
            ControlPanel,
            NomenclatureLotPick,
            StoragePicker,
            ShiftInput,
            MultiSelect,
            ErrorList,
            SuccessMessage,
            NomenclatureInput,
            UsersOfTheAct,
            WeighingScale,
            TemperatureAfterShockTable,
        },
        computed: {
            showTransferButton() {
                return (
                    this.currentActId !== null
                ) && !!this.storageTo && (
                    this.storageNotEqually || this.storageFrom.packing
                ) && this.startAndEndDateAreSet;
            },
            currentActId() {
                return this.$route.params.id ? this.$route.params.id : null
            },
            measureUnit() {
                return this.act.nomenclature ? this.act.nomenclature.measureUnit.name : ''
            },
            selectedItem() {
                return this.act.items.find( i => i.variant === 'secondary' )
            },
            disabled() {
                return true;
            },
            totalTaskCount() {
                return parseFloat(
                    sumBy( this.act.tasks, item => {
                        return parseFloat( item.count ? item.count : 0 );
                    } )
                ).toFixed( 3 );
            },
            totalCount() {
                const items = this.act ? this.act.items : [];
                return parseFloat(
                    sumBy( items, function ( item ) {
                        return parseFloat( item.count ? item.count : 0 );
                    } )
                ).toFixed( 3 );
            },
            overproducedCount() {
                return parseFloat((this.totalCount - this.totalTaskCount).toFixed(3));
            },
            overproducedPercent() {
                return this.totalCount > 0 ? Math.round(this.overproducedCount / this.totalCount * 100) : 0;
            },
            taskTabTitle() {
                if ( this.act.tasks.length === 1 ) {
                    return 'Задание'
                }

                return 'Задания (' + this.act.tasks.length + ')'
            },
            storageFrom() {
                if ( !this.act || !this.act.tasks || this.act.tasks.length <= 0 ) {
                    return null
                }

                let storageFrom = null

                for ( let i = 0; i < this.act.tasks.length; i++ ) {
                    if ( storageFrom === null ) {
                        storageFrom = this.act.tasks[ i ].storage
                    } else if ( storageFrom.id !== this.act.tasks[ i ].storage.id ) {
                        return null
                    }
                }

                return storageFrom
            },
            storageTo() {
                if ( !this.act || !this.act.tasks || this.act.tasks.length <= 0 ) {
                    return null
                }

                let storageTo = null

                if (this.storageFrom.packing) {
                    // Не передаем вручную из фасовки
                    return null;
                }

                for ( let i = 0; i < this.act.tasks.length; i++ ) {
                    if ( !this.act.tasks[ i ].parent ) {
                        return null
                    }

                    if ( storageTo === null ) {
                        storageTo = this.act.tasks[ i ].parent.storage
                    } else if ( storageTo.id !== this.act.tasks[ i ].parent.storage.id ) {
                        return null
                    }
                }

                return storageTo
            },
            materialTransferData() {
                let transmissionItem = {
                    nomenclature: this.act.nomenclature,
                    nomenclatureLot: !!this.act.produced_lot ? this.act.produced_lot : null,
                    count: 0
                };

                this.act.items.map( i => {
                    transmissionItem.count += parseFloat( i.count );
                } );

                let data = {
                    productionAct: {
                        id: this.act.id
                    },
                    transmissionItems: [ transmissionItem ],
                    storageFrom: this.act.storage
                };

                if ( this.storageTo ) {
                    data.storageTo = this.storageTo
                }

                return data
            },
            rawMaterialsNomenclatureIds() {
                if ( !this.act ) {
                    return []
                }

                return this.act.rawMaterials
                    .filter( i => i.nomenclature )
                    .map( i => i.nomenclature.id )
                    .sort( ( a, b ) => a - b )
            },
            accessForCreateQualityControlAct() {
                return this.$auth
                    .user()
                    .roles
                    .some( role => [ ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR ].includes( role ) );
            },
            allowedToSetAssumeFinished() {
                return this.$auth
                    .user()
                    .roles
                    .some( role => [ ROLE_ADMIN ].includes( role ) );
            },
            getNomenclatureRemainsParams() {
                return {
                    params: {
                        storage: { id: this.act.storage.id }
                    }
                };
            },
            storagePickerDisabled() {
                return this.disabled || this.$route.name === "ProductionActUpdate";
            },
            startDateDisabled() {
                return this.disabled || this.$route.name === "ProductionActUpdate";
            },
            shiftDisabled() {
                return this.disabled || this.$route.name === "ProductionActUpdate";
            },
            nomenclatureInputDisabled() {
                return this.disabled || this.$route.name === "ProductionActUpdate";
            },
            isHotStorage() {
                return this.act
                    && this.act.storage
                    && this.act.storage.name.toLowerCase() === 'горячий';
            },
        },
        watch: {
            currentActId() {
                this.resetData()
            },
            rawMaterialsNomenclatureIds( val, old ) {
                if ( val && difference( val, old ).length ) {
                    this.getNomenclatureLots()
                }
            },
            selectedRawMaterials( newVal ) {
                if ( newVal.length === 0 ) {
                    this.indeterminateRawMaterials = false;
                    this.allSelectedRawMaterials = false;
                } else if ( newVal.length === this.act.rawMaterials.length ) {
                    this.indeterminateRawMaterials = false;
                    this.allSelectedRawMaterials = true;
                } else {
                    this.indeterminateRawMaterials = true;
                    this.allSelectedRawMaterials = false;
                }
            }
        },
        data() {
            return {
                tabIndex: 0,
                act: null,
                task: null,
                errors: [],
                successCountDown: 0,
                nomenclatureLots: [],
                infoCollapsed: false,
                timeCollapsed: false,
                selectedRawMaterials: [],
                storageNotEqually: false,
                startAndEndDateAreSet: false,
                allSelectedRawMaterials: false,
                indeterminateRawMaterials: false,

                weight: 0,
            }
        },
        methods: {
            sumBy,
            moment,
            selectAllRawMaterials( checked ) {
                if ( !checked ) {
                    this.selectedRawMaterials = [];
                    return;
                }

                forEach( this.act.rawMaterials, ( material, index ) => this.selectedRawMaterials.push( index ) );
            },
            changeRawMaterialNomenclature( item, index ) {
                if ( item.nomenclature ) {
                    this.$http
                        .get(
                            NOMENCLATURES_ENDPOINT + '/' + item.nomenclature.id + '/remains',
                            this.getNomenclatureRemainsParams
                        )
                        .then( response => {
                            let remains = sumBy( response.data.data.remains, 'count' );
                            this.act.rawMaterials[ index ].remains = parseFloat( remains ).toFixed( 4 );
                        } );
                }
            },
            createQualityControlAct( materials = false ) {
                let items = [];

                if ( materials ) {
                    forEach( this.selectedRawMaterials, ( rawMaterialIndex ) => {
                        let material = this.act.rawMaterials[ rawMaterialIndex ];

                        items.push( {
                            count: material.count,
                            nomenclatureId: material.nomenclature.id,
                            nomenclatureLotId: material.nomenclatureLot ? material.nomenclatureLot.id : null
                        } );
                    } );
                } else {
                    items.push( {
                        count: sumBy( this.act.items, 'count' ),
                        nomenclatureId: this.act.nomenclature.id,
                        nomenclatureLotId: this.act.producedLot ? this.act.producedLot.id : null
                    } );
                }

                let params = {
                    transfer: {
                        shift: this.act.shift,
                        storage: this.act.storage,
                        date: this.act.date,
                        items: items
                    }
                };

                this.$router.push( { name: 'QualityControlActCreate', params: params } );
            },
            isRawMaterialValid( item ) {
                return item.count
                    && item.nomenclatureLot
                    && item.nomenclature;
            },
            selectItem( item ) {
                this.act.items.map( i => this.$set( i, 'variant', 'default' ) )
                this.$set( item, 'variant', 'secondary' )
            },
            selectRawMaterial( item, forceOpen = false ) {
                this.act.rawMaterials.map( i => this.$set( i, 'variant', 'default' ) )
                this.$set( item, 'variant', 'secondary' )
                this.$set( item, 'collapsed', forceOpen || item.collapsed !== true )
            },
            determinePossibilityOfTransfer() {
                this.startAndEndDateAreSet = !!this.act.startAt && !!this.act.endAt;
                this.storageNotEqually = this.storageTo && this.storageFrom && this.storageTo.id !== this.storageFrom.id
            },
            removeTask( index ) {
                this.act.tasks.splice( index, 1 )
            },
            resetData() {
                if ( this.currentActId ) {
                    this.fetchAct()
                        .then( () => {
                            if ( !this.act.endAt ) {
                                this.timeCollapsed = true
                            }

                            if ( this.act.items.length === 0 ) {
                                this.addItem( {} )
                            }
                        } )
                } else {
                    this.setNewAct()

                    if ( !this.act.storage || !this.act.shift ) {
                        this.infoCollapsed = true
                    }

                    if ( !this.act.nomenclature || !this.act.endAt ) {
                        this.timeCollapsed = true
                    }

                    if ( this.act.items.length === 0 ) {
                        this.addItem( {} )
                    }
                }
            },
            fetchAct() {
                return this.$http.get( this.getBaseUrl() + '/' + this.currentActId, {
                    params: {
                        with: WITH
                    }
                } )
                    .then( response => {
                        let act = response.data.data;

                        // Время
                        act.startDate = moment( act.start_at ).format( 'YYYY-MM-DD' );
                        act.startAt = moment( act.start_at ).format( 'HH:mm' );
                        act.endAt = act.end_at ? moment( act.end_at ).format( 'HH:mm' ) : null;

                        // Hack for format saving
                        act.items.map( item => {
                            if ( !item.box ) {
                                item.box = { name: null };
                            }
                        } );

                        act.rawMaterials.map( item => {
                            if ( !item.box ) {
                                item.box = { name: null };
                            }

                            if ( !item.remains ) {
                                item.remains = 0;
                            }
                        } );

                        this.act = act;

                        this.determinePossibilityOfTransfer();
                    } )
                    .catch(this.$root.responseError);
            },
            setNewAct() {
                let filtersJson = localStorage.getItem( 'ProductionActList-filter' ),
                    storage = null,
                    shift = null,
                    startDate = moment().format( 'YYYY-MM-DD' )

                if ( filtersJson ) {
                    filtersJson = JSON.parse( filtersJson )
                    storage = filtersJson.storage ? filtersJson.storage : null
                    shift = filtersJson.productionShift ? filtersJson.productionShift : null
                    startDate = filtersJson.dateFrom ? moment( filtersJson.dateFrom ).format( 'YYYY-MM-DD' ) : startDate
                }

                this.act = {
                    shift,
                    nomenclature: null,
                    storage,
                    comment: null,
                    items: [],
                    rawMaterials: [],
                    startDate,
                    startAt: moment().format( 'HH:mm' ),
                    endAt: null,
                    use_proportional_raw_materials: false
                }
            },
            getBaseUrl() {
                return PRODUCTION_ACTS_ENDPOINT;
            },
            clearEmptyItemsAndRawMaterials() {
                this.act.items = this.act.items.filter( i => i.count )
                this.act.rawMaterials = this.act.rawMaterials.filter( i => i.count && i.nomenclature )
            },
            save(event, overproductionConfirmed = false) {

                this.$toast.warning('Этот интерфейс доступен только для просмотра. Для работы используйте Рабочий стол');
                return;


                this.clearEmptyItemsAndRawMaterials();

                if (this.overproducedPercent > ALLOWED_OVERPRODUCTION_PERCENT && !overproductionConfirmed) {
                    this.$modal.show('OverproductionConfirmForm');
                    return;
                }

                let data = this.getDataForSave();

                if ( data.items.length !== uniqBy( data.items, 'box.name' ).length ) {
                    alert( 'В выпуске дублируются ящики' );
                    return null;
                }

                if ( !this.act.storage ) {
                    alert( 'Укажите цех' );
                    return null;
                }

                let remainsValidation = this.act.rawMaterials.findIndex( rawMaterial => {
                    return rawMaterial.remains < rawMaterial.count;
                } ) === -1;
                if ( !this.act.storage.allow_lot_below_zero && !remainsValidation ) {
                    alert( 'Не хватает сырья на складе' );
                    return null;
                }

                let globalModals = $app.global( 'modals' );
                let checkSavingProductionActModal = globalModals.getModal( 'CheckSavingProductionAct' );
                checkSavingProductionActModal.initModal( data, this.currentActId );
                checkSavingProductionActModal.$on( 'callback', data => {
                    this.successCountDown = 5;
                    console.log( data );
                    if ( !this.currentActId ) {
                        this.$router.push( { name: 'ProductionActUpdate', params: { id: data.id } } );
                    } else {
                        // Сетим т.к. на бэке мог произойти пересчет кол-ва по нормативу
                        let rawMaterials = data.data.raw_materials;
                        rawMaterials.map( item => {
                            if ( !item.box ) {
                                item.box = { name: null };
                            }

                            item.nomenclature.measureUnit = item.nomenclature.measure_unit;
                        } );
                        this.act.rawMaterials = rawMaterials;
                        this.act.endAt = !!data.data.end_at ? moment( data.data.end_at ).format( 'HH:mm' ) : null;
                        this.act.materialTransferActs = data.data.material_transfer_acts;
                        this.act.items = data.data.items;
                        this.determinePossibilityOfTransfer();
                        this.$forceUpdate();
                    }
                } );
            },
            getDataForSave() {
                let data = JSON.parse( JSON.stringify( this.act ) )

                if ( data.storage ) {
                    data.storage = { id: data.storage.id };
                }

                if ( data.nomenclature ) {
                    data.nomenclature = { id: data.nomenclature.id };
                }

                if ( data.shift ) {
                    data.shift = { id: data.shift.id };
                }

                if ( data.startDate && data.startAt ) {
                    data.start_at = moment( data.startDate + ' ' + data.startAt ).format( 'YYYY-MM-DD HH:mm:ss' )
                } else {
                    data.start_at = null
                }

                if ( data.startDate && data.endAt ) {
                    data.end_at = moment( data.startDate + ' ' + data.endAt ).format( 'YYYY-MM-DD HH:mm:ss' )

                    if ( data.startAt && data.endAt < data.startAt ) {
                        data.end_at = moment( data.end_at ).add( 1, 'days' ).format( 'YYYY-MM-DD HH:mm:ss' )
                    }
                } else {
                    data.end_at = null
                }

                data.items = []
                this.act.items.map( item => {
                    if ( !item.box.name ) {
                        item.box.name = BarcodeRepository.boxPrefixes[ 1 ] + Math.floor( Math.random() * (
                            99999 - 10000 + 1
                        ) + 10000 ).toString()
                    }

                    data.items.push( {
                        id: item.exists === false ? null : item.id,
                        box: item.box,
                        count: item.count ? parseFloat( item.count ) : 0,
                        defect: item.defect
                    } )
                } )

                data.rawMaterials = [];
                this.act.rawMaterials.map( material => {
                    data.rawMaterials.push( {
                        id: material.exists === false ? null : material.id,
                        box: material.box.name ? material.box : null,
                        nomenclature: material.nomenclature ? material.nomenclature : null,
                        nomenclatureLot: material.nomenclatureLot ? { id: material.nomenclatureLot.id } : null,
                        count: material.count ? parseFloat( material.count ) : 0
                    } )
                } );

                data.with = WITH;

                return data;
            },
            addItem( { box = { name: null } }, count = null ) {

                this.$toast.warning('Этот интерфейс доступен только для просмотра. Для работы используйте Рабочий стол');
                return;

                if (this.act.blocked) {
                    return;
                }
                let item = {
                    id: Math.ceil( Math.random() * 10000000 ),
                    exists: false,
                    box,
                    count: count,
                    defect: false
                }

                this.act.items.push( item )
                this.selectItem( item )
                this.$nextTick( () => {
                    window.scrollTo( 0, document.body.scrollHeight );
                } )
            },
            removeItem( index ) {
                if ( this.act.items[ index ].count || this.act.items[ index ].box.name ) {
                    if ( window.confirm( 'Удалить строку?' ) ) {
                        this.act.items.splice( index, 1 )
                    }
                } else {
                    this.act.items.splice( index, 1 )
                }
            },
            itemBlocked(item) {
                return !!item.blocked;
            },
            addRawMaterial( { box = { name: null }, nomenclatureLot = null, count = null, nomenclature = null } ) {
                if ( nomenclatureLot ) {
                    nomenclature = nomenclatureLot.nomenclature
                }

                let remains = 0;

                let rawMaterial = {
                    id: Math.ceil( Math.random() * 10000000 ),
                    exists: false,
                    nomenclature,
                    nomenclatureLot,
                    box,
                    count,
                    remains
                };

                this.act.rawMaterials.push( rawMaterial )
                this.selectRawMaterial( rawMaterial )
                this.$nextTick( () => {
                    window.scrollTo( 0, document.body.scrollHeight );
                } )
            },
            removeRawMaterial( index ) {
                if ( this.act.rawMaterials[ index ].nomenclature ) {
                    if ( window.confirm( 'Удалить строку?' ) ) {
                        this.act.rawMaterials.splice( index, 1 )
                    }
                } else {
                    this.act.rawMaterials.splice( index, 1 )
                }
            },
            onBarcodeScanned( data ) {
                if (data.barcode.startsWith(Barcode.weighingScalesPrefix())) {
                    this.getWeight(data.barcode);
                    return;
                }

                BarcodeRepository.findFirstResourceByBarcode( data.barcode )
                    .then( result => {
                        if ( !result ) return;

                        switch ( this.tabIndex ) {
                            case 0: // продукция
                                this.handleBarcodeOnItemsTab( result )
                                break
                            case 1: // сырье
                                this.handleBarcodeOnRawMaterialsTab( result )
                                break
                        }
                    } )
            },
            getWeight(barcode) {
                if (!this.act.nomenclature) {
                    this.$toast.error('Перед взвешиванием, укажите номенклатуру');
                    return;
                }
                if (!this.act.nomenclature.measureUnit) {
                    this.$toast.error('Отсутствует единица измерения');
                    return;
                }
                if (this.act.nomenclature.measureUnit.key !== 'kilos') {
                    this.$toast.error('Взвешивать можно только номенклатуру, которая измеряется в килограммах');
                    return;
                }
                this.$http.get(WEIGHING_SCALES_ENDPOINT + '/weight', {
                    params: {
                        code: barcode
                    }
                }).then(response => {
                    if (!response.data.isStable) {
                        this.$toast.error('Весы не стабильны, попробуйте еще раз');
                        return;
                    }

                    this.weight = response.data.weight;
                    this.$modal.show('weight');
                }).catch(response => {
                    let itemsShown = false;
                    if (!!response.data && !!response.data.errors) {
                        Object.keys(response.data.errors).map(k => {
                            response.data.errors[k].map(m => {
                                this.$toast.error(m);
                                itemsShown = true;
                            })
                        })
                    }

                    if (!itemsShown) this.$root.responseError(response, 'Ошибка!')
                });
            },
            handleBarcodeOnItemsTab( data ) {
                switch ( data.object.objectType ) {
                    case BOX_OBJECT_TYPE:
                        let item = this.act.items.find( i => i.box && i.box.name === data.resource.name )

                        if ( item ) {
                            this.selectItem( item )
                        } else {
                            this.addItem( { box: data.resource } )
                        }

                        break;
                }
            },
            handleBarcodeOnRawMaterialsTab( data ) {
                let item;

                switch ( data.object.objectType ) {
                    case BOX_OBJECT_TYPE:
                        item = this.act.rawMaterials.find( i => i.box && i.box.name === data.resource.name )

                        if ( item ) {
                            this.selectRawMaterial( item, true )
                        } else {
                            item = { box: data.resource }

                            this.getBoxContents( data.resource )
                                .then( response => {
                                    if ( response && response.data && response.data.length > 0 ) {
                                        item.count = response.data[ 0 ].count

                                        if ( response.data[ 0 ].nomenclatureLotId ) {
                                            this.$http
                                                .get(NOMENCLATURE_LOTS_ENDPOINT + '/' + response.data[0].nomenclatureLotId)
                                                .then(response => response.data.data)
                                                .then( nomenclatureLot => {
                                                    if ( nomenclatureLot ) {
                                                        let existingItemWithoutBoxAndLot = this.act.rawMaterials.find( i => {
                                                            return !i.nomenclatureLot // без партии
                                                                && !i.box.name // без ящика
                                                                && i.nomenclature
                                                                && i.nomenclature.id === nomenclatureLot.nomenclature.id // с той же номенклатурой
                                                        } )

                                                        // Нашли итем без партии и ящика, но с той же номенклатурой
                                                        if ( existingItemWithoutBoxAndLot ) {
                                                            this.$set( existingItemWithoutBoxAndLot, 'nomenclatureLot', nomenclatureLot )
                                                            this.$set( existingItemWithoutBoxAndLot, 'box', item.box )

                                                            // Если в данных о ящике количества больше чем в исходной "пустой" строке, то оставляем кол-во из строки.
                                                            // Если в ящике меньше, то количество в строку проставляем из ящика
                                                            if ( item.count < existingItemWithoutBoxAndLot.count ) {
                                                                this.$set( existingItemWithoutBoxAndLot, 'count', item.count )
                                                            }
                                                        } else {
                                                            this.addRawMaterial( { ...item, nomenclatureLot } )
                                                        }
                                                    } else {
                                                        this.addRawMaterial( item )
                                                    }
                                                } )
                                        } else if ( response.data[ 0 ].nomenclatureId ) {
                                            this.$http
                                                .get(NOMENCLATURES_ENDPOINT + '/' + response.data[0].nomenclatureId)
                                                .then(response => response.data.data)
                                                .then(nomenclature => {
                                                    this.addRawMaterial({...item, nomenclature})
                                                });
                                        } else {
                                            this.addRawMaterial( item )
                                        }
                                    } else {
                                        this.addRawMaterial( item )
                                    }
                                } )
                        }

                        break;
                    case NOMENCLATURE_LOT_OBJECT_TYPE:
                        item = this.act.rawMaterials.find( i => i.nomenclatureLot && i.nomenclatureLot.id === data.resource.id )

                        if ( item ) {
                            this.selectRawMaterial( item )
                        } else {
                            this.addRawMaterial( { nomenclatureLot: data.resource } )
                        }

                        break;
                }
            },
            getBoxContents( box ) {
                let date = this.act.startDate && this.act.startAt
                    ? moment( this.act.startDate + ' ' + this.act.startAt ).format( 'YYYY-MM-DD HH:mm:ss' )
                    : null

                let storage_id = this.act.storage ? this.act.storage.id : null

                if ( box.id ) {
                    return this.$http.post( `boxes/state`, {
                        box_id: box.id,
                        storage_id,
                        date
                    } )
                } else {
                    return this.$http.post( `boxes/${box.name}` )
                        .then( response => {
                            if ( response.data.id ) {
                                return this.$http.post( `boxes/state`, {
                                    box_id: response.data.id,
                                    storage_id,
                                    date
                                } )
                            }

                            return null
                        } )
                }
            },
            getNomenclatureLots() {
                this.nomenclatureLots = [];

                if ( !this.act || !this.act.storage ) {
                    return null;
                }

                let nomenclatureIds = this.rawMaterialsNomenclatureIds;
                forEach( this.act.rawMaterials, rawMaterial => {
                    if ( !rawMaterial.nomenclature ) {
                        return true;
                    }

                    let rawMaterialId = rawMaterial.id;
                    let nomenclatureId = rawMaterial.nomenclature.id;
                    this.$http
                        .get( NOMENCLATURES_ENDPOINT + '/' + nomenclatureId + '/remains', this.getNomenclatureRemainsParams )
                        .then( response => {
                            let remains = sumBy( response.data.data.remains, 'count' );
                            let rawMaterialIndex = this.act.rawMaterials.findIndex( rawMaterial => {
                                return rawMaterial.id === rawMaterialId;
                            } );

                            this.act.rawMaterials[ rawMaterialIndex ].remains =
                                remains !== 0 ? parseFloat( remains ).toFixed( 4 ) : 0;
                        } );
                } );

                this.$http.get( NOMENCLATURE_LOTS_ENDPOINT, {
                    params: {
                        without_loading: true,
                        storageId: this.act.storage.id,
                        nomenclatureIds: nomenclatureIds
                    }
                } )
                    .then( response => {
                        this.nomenclatureLots = response.data.data
                    } )
            },
            errorMessage( error ) {
                if ( typeof error === 'string' ) {
                    return error
                }

                let itemMatched = error.propertyPath.match( /^items\[(\d+)]/ ),
                    rawMaterialMatched = error.propertyPath.match( /^rawMaterials\[(\d+)]/ ),
                    message = error.message;

                if ( itemMatched ) {
                    message = `Продукция ${itemMatched[ 1 ]}: ${message}`
                }

                if ( rawMaterialMatched ) {
                    message = `Сырьё ${rawMaterialMatched[ 1 ]}: ${message}`
                }

                return message
            },
        },
        mounted() {
            this.resetData()
            this.$root.listenToBarcodeScanning( this )
        }
    }
</script>

<style>
    .production-act-form input, .production-act-form select {
        font-size: 14px;
    }

    .production-act-form .card.bg-secondary {
        color: white !important;
    }

    .production-act-form .card.bg-secondary .text-muted {
        color: white !important;
    }

    .production-act-form .valid-item {
        background: #28a745;
        border-radius: 5px;
        padding: 0 2px;
        color: white;
    }

    .production-act-form .not-valid-item {
        background: rgba(236, 46, 64, 0.91);
        border-radius: 5px;
        padding: 0 2px;
        color: white;
    }

    .production-act-form .multiselect__content-wrapper {
        position: relative;
    }

    .production-act-form .nav-tabs .nav-item {
        font-size: smaller;
    }

    .production-act-form .input-group-text {
        padding: 0.28rem 0.75rem;
    }

    .item-input:invalid {
        border: 2px solid red;
    }
</style>

<style>
    #OverproductionConfirmForm {
        background: rgba(0, 0, 0, 0.5);
    }
    #OverproductionConfirmForm .card-body {
        background-color: #fee2e1;
    }
</style>
