<template>
    <div class="card">
        <div class="card-header text-center">Взвешивание</div>
        <div class="card-body">
            <div class="text-center">Брутто:</div>
            <div style="font-size: 36px;" class="text-center">{{(weight/1000).toFixed(3)}} кг</div>
            <template v-if="tare.length">
                <div class="text-center">Нетто:</div>
                <div style="font-size: 36px;" class="text-center">{{((weight - tareWeight)/1000).toFixed(3)}} кг</div>
            </template>
            <div class="table-responsive">
                <table class="table table-sm table-bordered">
                    <thead class="thead-dark">
                    <tr>
                        <th>Гастрик</th>
                        <th class="text-right">Масса</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="tare.length === 0">
                        <td colspan="2" class="text-center">
                            <div class="alert alert-info mb-0">
                            Начните сканировать ШК гастриков
                            </div>
                        </td>
                    </tr>
                    <template v-else>
                        <tr v-for="box in tare">
                            <td>{{box.name}}</td>
                            <td class="text-right">{{(box.sole_weight/1000).toFixed(3)}} кг</td>
                        </tr>
                    </template>
                    </tbody>
                    <tfoot v-if="tare.length !== 0">
                        <tr>
                            <td class="text-right">Итого:</td>
                            <td class="text-right">{{(tareWeight/1000).toFixed(3)}} кг</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-4"><button @click="cancel()" class="btn btn-danger">Отмена</button></div>
                <div class="col-8"><button @click="done()" class="btn btn-success" style="width: 100%;">Готово</button></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {sumBy, findIndex} from 'lodash'
    import Barcode from "../../../utils/Barcode";
    import {BOX_ENDPOINT} from "../../../utils/endpoints";

    export default {
        name: "WeighingScale",
        props: {
            weight: {
                type: Number,
                required: true,
            }
        },
        computed: {
            tareWeight() {
                return sumBy(this.tare, 'sole_weight');
            },
        },
        data() {
            return {
                tare: [],
            };
        },
        methods: {
            sumBy,
            onBarcodeScanned(data) {
                if (data.barcode.startsWith(Barcode.gastronormContainerPrefix())) {
                    let index = findIndex(this.tare, i => i.name === data.barcode);

                    if (index !== -1) {
                        this.$toast.error('Вы уже добавили этот гастрик');
                        return;
                    }

                    this.getBox(data.barcode);
                    return;
                }

                this.$toast.error('Неверный ШК!');
            },
            getBox(barcode) {
                this.$http.post(BOX_ENDPOINT + '/first_or_create', {
                    name: barcode,
                    type_code: 'gastronorm',
                }).then(response => {
                    if (!!response.data.sole_weight) {
                        this.tare.push(response.data);
                    } else {
                        this.$toast.error('Для гастрика '+response.data.name+' не назначен вес');
                    }
                }).catch(response => {
                    if (!!response.data && !!response.data.message) {
                        this.$toast.error(response.data.message);
                    }
                });
            },
            done() {
                let result = this.weight - this.tareWeight;
                if (result < 0) {
                    this.$toast.error('Получился отрицательный вес');
                    return;
                }

                this.$emit('input', result);
            },
            cancel() {
                this.$emit('cancel');
            }
        },
        mounted() {
            this.$root.listenToBarcodeScanning(this);
        }
    }
</script>

<style scoped>

</style>
